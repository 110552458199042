define("discourse/plugins/di-forum-discourse-plugin-markerio/discourse/initializers/markerIo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "MarkerIo",
    initialize() {
      this.activateMarkerIo();
    },
    async activateMarkerIo() {
      const script = document.createElement("script");
      window.markerConfig = {
        project: "65adc2d6527704155a64bf36"
      };
      script.src = "//edge.marker.io/latest/shim.js";
      script.async = true;
      document.body.appendChild(script);
    }
  };
});